define("discourse/plugins/discourse-data-explorer/discourse/components/share-report", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _getUrl, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="share-report">
    <a href="#" {{on "click" this.open}} class="share-report-button">
      {{d-icon "link"}}
      {{@group}}
    </a>
  
    {{#if this.visible}}
      <div
        class="popup"
        {{did-insert this.registerListeners}}
        {{will-destroy this.unregisterListeners}}
      >
        <label>{{i18n "explorer.link"}} {{@group}}</label>
        <input type="text" value={{this.link}} {{did-insert this.focusInput}} />
  
        <DButton
          @action={{this.close}}
          @class="btn-flat close"
          @icon="times"
          @aria-label="share.close"
          @title="share.close"
        />
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "Ivu9lXlQ",
    "block": "[[[10,0],[14,0,\"share-report\"],[12],[1,\"\\n  \"],[11,3],[24,6,\"#\"],[24,0,\"share-report-button\"],[4,[38,0],[\"click\",[30,0,[\"open\"]]],null],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"link\"],null]],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"visible\"]],[[[1,\"    \"],[11,0],[24,0,\"popup\"],[4,[38,3],[[30,0,[\"registerListeners\"]]],null],[4,[38,4],[[30,0,[\"unregisterListeners\"]]],null],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,[28,[35,5],[\"explorer.link\"],null]],[1,\" \"],[1,[30,1]],[13],[1,\"\\n      \"],[11,\"input\"],[16,2,[30,0,[\"link\"]]],[24,4,\"text\"],[4,[38,3],[[30,0,[\"focusInput\"]]],null],[12],[13],[1,\"\\n\\n      \"],[8,[39,6],null,[[\"@action\",\"@class\",\"@icon\",\"@aria-label\",\"@title\"],[[30,0,[\"close\"]],\"btn-flat close\",\"times\",\"share.close\",\"share.close\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@group\"],false,[\"on\",\"d-icon\",\"if\",\"did-insert\",\"will-destroy\",\"i18n\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/share-report.hbs",
    "isStrictMode": false
  });
  let ShareReport = _exports.default = (_class = class ShareReport extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "visible", _descriptor, this);
      _defineProperty(this, "element", void 0);
    }
    get link() {
      return (0, _getUrl.default)(`/g/${this.args.group}/reports/${this.args.query.id}`);
    }
    mouseDownHandler(e) {
      if (!this.element.contains(e.target)) {
        this.close();
      }
    }
    keyDownHandler(e) {
      if (e.keyCode === 27) {
        this.close();
      }
    }
    registerListeners(element) {
      if (!element || this.isDestroying || this.isDestroyed) {
        return;
      }
      this.element = element;
      document.addEventListener("mousedown", this.mouseDownHandler);
      element.addEventListener("keydown", this.keyDownHandler);
    }
    unregisterListeners(element) {
      this.element = element;
      document.removeEventListener("mousedown", this.mouseDownHandler);
      element.removeEventListener("keydown", this.keyDownHandler);
    }
    focusInput(e) {
      e.select();
      e.focus();
    }
    open(e) {
      e.preventDefault();
      this.visible = true;
    }
    close() {
      this.visible = false;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "visible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "mouseDownHandler", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "mouseDownHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "keyDownHandler", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "keyDownHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerListeners", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerListeners"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unregisterListeners", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unregisterListeners"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "focusInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "open", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ShareReport);
});